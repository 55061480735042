import React, { FC, useCallback, useRef, useState } from 'react'
import { recordMailingListSignup } from '../../lib/Analytics'
import { subscribe, SubscribeState } from '../../lib/SubscribeUtils'
import { colors, fonts, mq, spaces, styled } from '../../styles'
import { MailingListSectionData } from '../../types'
import Button from '../Button'
import EmailSignupForm from '../EmailSignupForm'
import Section from '../Section'
import Title from '../Title'

interface SignupSectionPresentationProps extends SignupSectionProps {
  emailInputRef: React.RefObject<HTMLInputElement>
  onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void) | undefined
  onSubmit?: ((event: React.MouseEvent<HTMLButtonElement>) => void) | undefined
  errorMessage?: string
  subscribeState: SubscribeState
  disabledButton: boolean
}

const SignupSectionPresentation: FC<SignupSectionPresentationProps> = (
  props,
) => {
  let buttonText
  switch (props.subscribeState) {
    case SubscribeState.Started:
      buttonText = 'PROCESSING...'
      break
    case SubscribeState.Complete:
      buttonText = 'SIGNED UP!'
      break
    default:
      buttonText = 'SIGN UP'
      break
  }
  return (
    <Container>
      <Title>{props.section.section_title?.text}</Title>
      <BodyText>{props.section.subtitle?.text}</BodyText>
      <FormContainer>
        <EmailSignupForm theme="light" />
      </FormContainer>
    </Container>
  )
}

const Container = styled(Section)({
  flexDirection: 'column',
})

const BodyText = styled.div(
  mq({
    fontFamily: fonts.garamond.condensed,
    whiteSpace: 'pre-line',
    textAlign: 'center',
    lineHeight: 1,
    marginLeft: [20, 20, 20, 0],
    marginRight: [20, 20, 20, 0],
    fontSize: ['3.5em', '1.5em', '1.5em', '1.6em'],
  }),
)

const FormContainer = styled.div(
  mq({
    marginTop: spaces.small,
  }),
)

const SmallBodyText = styled.div<{ isError?: boolean }>((props) =>
  mq({
    textAlign: 'center',
    paddingTop: 8,
    fontSize: '2em',
    fontFamily: fonts.monosten.light,
    color: props.isError ? 'red' : 'black',
    paddingBottom: props.isError ? 5 : 0,
    marginLeft: [20, 10, 70, 20],
    marginRight: [20, 10, 70, 20],
  }),
)

const SignupForm = styled.form({
  display: 'flex',
  flexDirection: 'column',
  paddingTop: 15,
})

const FormInput = styled.input(
  {
    margin: '10px 0px 10px 0px',
    alignSelf: 'center',
    width: '100%',
    maxWidth: 670,
    border: '1px solid black',
    padding: '10px 20px 10px 20px',
    backgroundColor: '#E6E1DA',
    '::-webkit-input-placeholder': {
      color: '#B6B4AF',
    },
    ':focus': {
      borderRadius: 10,
      outline: 'none',
      boxShadow: `2px 2px 0px grey`,
    },
  },
  (props) => ({
    fontFamily: fonts.monosten.light,
    ':-webkit-autofill': {
      boxShadow: `0px 0px 0px 60px #E6E1DA inset`,
      // WebkitBackgroundClip: 'text', // make it transparent
    },
    ':-webkit-autofill::first-line': {
      fontFamily: fonts.monosten.light,
      fontSize: 'inherit',
    },
  }),
  mq({
    borderRadius: [10, 7, 7, 7],
    fontSize: ['80px', '62px', '62px', '62px'],
    margin: [
      '10px 0px 10px 0px',
      '6px 0px 6px 0px',
      '6px 0px 6px 0px',
      '6px 0px 6px 0px',
    ],
    ':-webkit-autofill::first-line': {
      fontSize: ['80px', '72px', '72px', '72px'],
    },
  }),
)

const SubmitContainer = styled('div')(
  {
    margin: '0 auto',
    marginTop: '.5em',
  },
  mq({
    marginTop: ['2em', '.5em'],
    width: ['auto', '100%'],
  }),
)

const BigButton = styled(Button)(
  {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '60vw',
    margin: '0 auto',
    height: '10vw',
    borderRadius: '5vw',
    fontSize: '6vw',
    border: 0,
    fontFamily: fonts.topol,
    backgroundColor: colors.darkBrown,
    textDecoration: 'none',
    color: colors.lightBrown,
  },
  mq({
    width: ['60vw', '100%'],
    height: ['10vw', 100],
    borderRadius: ['5vw', 63],
    marginTop: [0, '0em'],
    fontSize: ['6vw', '3em'],
  }),
)

interface SignupSectionProps {
  section: MailingListSectionData
}

const SignupSectionContainer: FC<SignupSectionProps> = (props) => {
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [errorMessage, setErrorMessage] = useState<string | undefined>()
  const [subscribeState, setSubscribeState] = useState<SubscribeState>(
    SubscribeState.Initial,
  )
  const [disabledButton, setDisabledButton] = useState(false)

  const emailInputRef = useRef(null)

  const handleInputChanged = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const target = event.target
      const value = target.value
      if (target.name === 'name') setName(value)
      else setEmail(value)
    },
    [],
  )

  const handleSubmit = useCallback(
    async (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault()
      if (email === '') {
        setErrorMessage('Please fill in your name and email.')
      } else {
        setSubscribeState(SubscribeState.Started)
        const result = await subscribe(email, name)
        if (result.state !== SubscribeState.Complete) {
          setErrorMessage(result.msg)
          setSubscribeState(SubscribeState.Failed)
        } else {
          setErrorMessage(undefined)
          setSubscribeState(SubscribeState.Complete)
          setDisabledButton(true)
          recordMailingListSignup()
        }
      }
    },
    [name, email],
  )

  return (
    <SignupSectionPresentation
      {...props}
      emailInputRef={emailInputRef}
      errorMessage={errorMessage}
      subscribeState={subscribeState}
      disabledButton={disabledButton}
      onChange={handleInputChanged}
      onSubmit={handleSubmit}
    />
  )
}

export default SignupSectionContainer
