import { mq, styled } from '../styles'

const Section = styled.div<{ withoutTitle?: boolean }>(({ withoutTitle }) =>
  mq({
    display: 'flex',
    width: '100%',
    borderTop: '1px solid black',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: withoutTitle
      ? ['6em 2em', '2.5em 1em']
      : ['4em 2em 6em', '2em 1em 2.5em'],
  }),
)

export default Section
