import React, { FC, useCallback, useState } from 'react'
import { graphql, PageProps } from 'gatsby'
import styled from '@emotion/styled'

import '../components/base.css'
import Layout from '../components/Layout'
import { FaqQuery } from '../../graphql-types'
import { withPreview } from 'gatsby-source-prismic'
import extractGlobals from '../lib/extractGlobals'
import DocumentContent from '../components/DocumentContent'
import { fonts, mq } from '../styles'
import { MailingListSectionData } from '../types'
import SignupSection from '../components/sections/SignupSection'
import { createRichTextComponent } from '../components/RichText'
import { RichTextBlock } from '../components/PrismicRichText'
import { recordFaqView } from '../lib/Analytics'

function FAQ({ data }: PageProps<FaqQuery>) {
  const { meta, footer } = extractGlobals(data)
  const title = data.prismicSplashFaqs?.data?.page_title
  const body = data.prismicSplashFaqs?.data?.body
  const sectionData = data.prismicSplashMailingListSection?.data
  const signupSection: MailingListSectionData = {
    ...sectionData,
    type: 'splash_mailing_list_section',
  }
  return (
    <Layout tabNames={[]} meta={meta} footer={footer} showFinalPreorder={true}>
      <DocumentContent title={title?.text} content={null}>
        <QuestionList>
          {body?.map((b, sectionIndex) => {
            return (
              <QuestionSection key={sectionIndex}>
                <QuestionSectionTitle>
                  {b?.primary?.name?.text}
                </QuestionSectionTitle>
                <QuestionSectionQuestions>
                  {b?.items?.map((item, i) => {
                    return (
                      item?.question?.text &&
                      item?.answer?.raw && (
                        <Question
                          key={i}
                          questionText={item.question.text}
                          answer={item.answer.raw}
                        />
                      )
                    )
                  })}
                </QuestionSectionQuestions>
              </QuestionSection>
            )
          })}
        </QuestionList>
        {sectionData && <SignupSection section={signupSection}></SignupSection>}
      </DocumentContent>
    </Layout>
  )
}

const Question: FC<{ questionText: string; answer: RichTextBlock[] }> = ({
  questionText,
  answer,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const handleClickExpand = useCallback(() => {
    setIsExpanded((expanded) => !expanded)
    if (!isExpanded) recordFaqView(questionText)
  }, [isExpanded, questionText])

  return (
    <QuestionContainer onClick={handleClickExpand}>
      <QuestionTextContainer>
        <QuestionText>
          {questionText}
          <ExpandQuestionButton>
            <span>{isExpanded ? '-' : '+'}</span>
          </ExpandQuestionButton>
        </QuestionText>
      </QuestionTextContainer>
      <AnswerText expanded={isExpanded}>
        <AnswerTextContent blocks={answer}></AnswerTextContent>
      </AnswerText>
    </QuestionContainer>
  )
}

const AnswerTextContent = createRichTextComponent({})

const QuestionList = styled('div')(
  mq({
    borderBottom: '1px solid black',
    paddingBottom: ['8rem', '4rem'],
  }),
)

const QuestionSection = styled('div')(
  mq({
    margin: '0 auto',
    textAlign: 'center',
    marginBottom: ['4em', '2em'],
    padding: [0, '0 1em'],
    maxWidth: 1020,
    breakInside: 'avoid',
  }),
)

const QuestionSectionTitle = styled('div')({
  margin: '0 auto',
  fontSize: '1.5em',
  textTransform: 'uppercase',
  marginBottom: '1rem',
})

const QuestionSectionQuestions = styled('div')({
  border: '1px solid black',
  borderRadius: 20,
})

const QuestionContainer = styled('div')(
  mq({
    cursor: 'pointer',
    borderBottom: '1px solid black',
    position: 'relative',
    padding: '1.6em 1.5em 1.25em',
    ':last-child': {
      borderBottom: 0,
    },
  }),
)

const ExpandQuestionButton = styled('button')(
  mq({
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: 0,
    top: 0,
    bottom: 0,
    fontSize: 32,
    fontFamily: fonts.monosten.light,
    span: {
      position: 'relative',
      top: '-.4rem',
    },
  }),
)

const QuestionTextContainer = styled('div')(
  mq({
    cursor: 'pointer',
    position: 'relative',
    paddingRight: '4rem',
  }),
)

const QuestionText = styled('div')(
  mq({
    textAlign: 'left',
    fontFamily: fonts.garamond.condensed,
    fontSize: ['1.7em', '1.5em'],
    lineHeight: 1.2,
    fontWeight: 100,
  }),
)

const AnswerText = styled('div')<{ expanded: boolean }>(({ expanded }) =>
  mq({
    fontFamily: fonts.monosten.light,
    textAlign: 'left',
    maxWidth: 500,
    display: expanded ? 'block' : 'none',
    lineHeight: 1.4,
  }),
)

export default withPreview(FAQ, { mergeStrategy: 'traverseAndReplace' })

export const pageQuery = graphql`
  query Faq {
    prismicSplash {
      _previewable
      data {
        ...Meta
        ...Footer
      }
    }
    prismicSplashFaqs {
      _previewable
      data {
        page_title {
          text
        }
        body {
          ... on PrismicSplashFaqsBodyQuestion {
            items {
              question {
                text
              }
              answer {
                raw
              }
            }
            primary {
              name {
                text
              }
            }
          }
        }
      }
    }
    prismicSplashMailingListSection {
      ...MailingListSection
    }
  }
`
